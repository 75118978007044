import React from "react";
import ReactDOM from 'react-dom/client';

import App from "./components/App";

import "./reset.css";
import "./styles.css";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);