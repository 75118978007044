import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Game from "../Game";
import { Toaster } from "../ui/toaster";
import PuzzleDataProvider from "../../providers/PuzzleData";
import GameStatusProvider from "../../providers/GameStatus";
import HowToPlayPopup from "../HowToPlayPopup";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from "react-share";

const shareUrl = "https://www.connexions.fun";
function App() {
  return (
    <PuzzleDataProvider>
      <GameStatusProvider>
        <div className="wrapper whitespace-nowrap overflow-auto scrollbar-hide">
          <Toaster />
          <Header />
          <div className="flex mx-auto items-center content-center mt-4 mb-4 shadow-lg p-2 text-center gap-2">
            <h2 className="text-3xl text-slate-800 font-bold">Connexions :</h2>
          </div>
          <Game />
          <div className="mx-auto border border-gray-500 w-[90%]"></div>
          <div className="flex mx-auto content-center items-center gap-2 mt-8 mb-8">
            <div>
              <HowToPlayPopup />
            </div>
            <div className="px-1 mt-6">
              <FacebookShareButton url={shareUrl} title={`Jouez à Connexions`}>
                <FacebookIcon round size={48} />
              </FacebookShareButton>
            </div>
            <div className="px-1 mt-6">
              <TwitterShareButton url={shareUrl} title={`Jouez à Connexions`}>
                <TwitterIcon round size={48} />
              </TwitterShareButton>
            </div>
          </div>
          <Footer />
        </div>
      </GameStatusProvider>
    </PuzzleDataProvider>
  );
}

export default App;
