import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography} from '@mui/material';

function HowToPlayPopup() {
    const [open, setOpen] = useState(true);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="w-5/6 flex justify-center overflow-hidden p-4">
            <button className="bg-cyan-900 hover:bg-cyan-700 text-white p-4 rounded-md font-medium"
                onClick={handleOpen}
            > Comment Jouer
            </button>
            <Dialog open={open} onClose={handleClose} disableScrollLock={true} >
                <DialogTitle>Bienvenue à connexions!</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                    </Typography>
                     <Typography variant="body1">
                      Trouvez des groupes de quatre éléments partageant un point commun.
                      <ul>
                        <li>Sélectionnez quatre éléments et cliquez sur <b>Soumettre</b> pour vérifier si votre supposition est correcte.</li>
                        <li>Trouvez les groupes sans faire 4 erreurs !</li>
                     </ul>
                    </Typography>
                    <Typography variant="body1">-</Typography>
                    <Typography variant="body2">
                        <b>Exemples de Catégories:</b>
                        <ul>
                            <li>Couleurs: Chartreuse, Aigue-marine, Cinabre, Indigo</li>
                            <li>Vêtements: Chemise, Pantalon, Chapeau, Chaussure</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1">-</Typography>
                    <Typography variant="body2">
                      Une couleur est attribuée à chaque groupe et sera révélée au fur et à mesure de votre progression:
                        <ul className="mb-4">
                            <li>Facile = 🟨</li>
                            <li>Moyen = 🟩</li>
                            <li>Difficile = 🟦</li>
                            <li>Expert = 🟪</li>
                        </ul>
                        <span className="text-cyan-800">N'oubliez pas de partager le jeu avec vos amis, si vous le trouvez intéressant.</span>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleClose} color="primary" className="bg-[#10172A] hover:bg-[#0C1425] text-white p-2 rounded">
                        Fermer
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default HowToPlayPopup;
