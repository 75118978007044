export const CONNECTION_GAMES = [
  [
    {
      category: "À une fête",
      words: ["MUSIQUE", "DANSE", "INVITÉS", "DÉCORATION"],
      difficulty: 1,
    },
    {
      category: "Au casino",
      words: ["JETON", "ROULETTE", "BLACKJACK", "JACKPOT"],
      difficulty: 2,
    },
    {
      category: "À l'aéroport",
      words: ["DOUANE", "BAGAGE", "TERMINAL", "PASSAGER"],
      difficulty: 3,
    },
    {
      category: "Autour d'un microscope",
      words: ["LAME", "LENTILLES", "CELLULE", "OBJECTIF"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Senteur",
      words: ["BOISÉE", "FRUITÉE", "MARINE", "FLEURIE"],
      difficulty: 1,
    },
    {
      category: "Chez le Coiffeur",
      words: ["SHAMPOING", "CISEAUX", "COLORATION", "MÈCHES"],
      difficulty: 2,
    },
    {
      category: "Corps",
      words: ["BRAS", "MUSCLE", "PEAU", "OS"],
      difficulty: 3,
    },
    {
      category: "Café",
      words: ["MOULU", "EXPRESSO", "TASSE", "ARABICA"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Art",
      words: ["PEINTURE", "MUSIQUE", "DANSE", "CINÉMA"],
      difficulty: 1,
    },
    {
      category: "Clown",
      words: ["NEZ", "PERRUQUE", "CIRQUE", "RIRE"],
      difficulty: 2,
    },
    {
      category: "Dents",
      words: ["MOLAIRE", "CANINE", "BROSSE", "CARIE"],
      difficulty: 3,
    },
    {
      category: "E-mail",
      words: ["ENVOYER", "ATTACHER", "BROUILLON", "SPAM"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Cuisine",
      words: ["SOUPE", "INGRÉDIENT", "SALADE", "MIXEUR"],
      difficulty: 1,
    },
    {
      category: "Trains",
      words: ["WAGON", "RAILS", "LOCOMOTIVE", "GARE"],
      difficulty: 2,
    },
    {
      category: "Selfie",
      words: ["CAMERA", "FILTRE", "FLASH", "SOURIRE"],
      difficulty: 3,
    },
    {
      category: "Réactions Allergiques",
      words: ["ÉTERNUMENT", "DÉMANGEAISON", "ANTICORPS", "ROUGEUR"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Pain",
      words: ["FARINE", "LEVURE", "PÉTRISSAGE", "FOUR"],
      difficulty: 1,
    },
    {
      category: "SKI",
      words: ["NEIGE", "FROID", "PISTE", "MONTAGNE"],
      difficulty: 2,
    },
    {
      category: "Ordinateur",
      words: ["CÂBLE", "ÉCRAN", "SOURIS", "CLAVIER"],
      difficulty: 3,
    },
    {
      category: "Distributeur Automatique",
      words: ["CHIPS", "BILLETS", "BOISSON", "CHOCOLAT"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Plage",
      words: ["SABLE", "SERVIETTE", "SOLEIL", "VAGUE"],
      difficulty: 1,
    },
    {
      category: "Littérature",
      words: ["ÉCRIVAIN", "DRAME", "FICTION", "LIVRE"],
      difficulty: 2,
    },
    {
      category: "Arc-en-ciel",
      words: ["PLUIE", "COULEURS", "PRISME", "CIEL"],
      difficulty: 3,
    },
    {
      category: "Hérédité",
      words: ["GÈNES", "YEUX", "TAILLE", "VISAGE"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "MUSIQUE",
      words: ["ROCK", "JAZZ", "BLUES", "POP"],
      difficulty: 1,
    },
    {
      category: "Safari",
      words: ["LION", "ÉLÉPHANT", "BABOUIN", "GAZELLE"],
      difficulty: 2,
    },
    {
      category: "Sommeil",
      words: ["REPOS", "SIESTE", "RÉVEIL", "ALARME"],
      difficulty: 3,
    },
    {
      category: "Barbecue",
      words: ["GRILLADE", "CHARBON", "BROCHETTE", "FUMÉE"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Téléphone",
      words: ["COMPOSER", "SONNERIE", "APPEL", "BOUTON"],
      difficulty: 1,
    },
    {
      category: "Au-dessus de votre tête",
      words: ["PLAFOND", "TOIT", "CIEL", "LAMPE"],
      difficulty: 2,
    },
    {
      category: "Adjectifs commençant par 'S'",
      words: ["SUBLIME", "SÉRIEUX", "SPONTANÉ", "SYMPA"],
      difficulty: 3,
    },
    {
      category: "Boissons alcoolisées",
      words: ["VODKA", "TEQUILA", "WHISKY", "GIN"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Jeux",
      words: ["CARTE", "DÉ", "STRATÉGIE", "CONSOLE"],
      difficulty: 1,
    },
    {
      category: "Famille",
      words: ["ONCLE", "FILS", "COUSIN", "SOEUR"],
      difficulty: 2,
    },
    {
      category: "Archéologie",
      words: ["FOUILLE", "POTERIE", "TOMBE", "STATUE"],
      difficulty: 3,
    },
    {
      category: "Abeilles",
      words: ["REINE", "RUCHE", "MIEL", "JAUNE"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "En mer",
      words: ["MARIN", "BOUÉE", "BATEAU", "PÊCHEUR"],
      difficulty: 1,
    },
    {
      category: "Animaux",
      words: ["GAZELLE", "GORILLE", "LION", "CHÈVRE"],
      difficulty: 2,
    },
    {
      category: "Archéologie",
      words: ["POTERIE", "FOSSILE", "LANCE", "ÉPÉE"],
      difficulty: 3,
    },
    {
      category: "Parc d'attractions",
      words: ["JEUX", "TREMPLIN", "TICKET", "TOBOGGAN"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Pâtisserie",
      words: ["GÂTEAU", "ÉCLAIR", "CROISSANT", "MACARON"],
      difficulty: 1,
    },
    {
      category: "À la maison",
      words: ["TAPIS", "LUMIÈRE", "DOUCHE", "MIROIR"],
      difficulty: 2,
    },
    {
      category: "Chansons des Beatles",
      words: ["YESTERDAY", "HELP", "LET-IT-BE", "HEY-JUDE"],
      difficulty: 3,
    },
    {
      category: "Civilisations anciennes",
      words: ["ROMAINE", "GRECQUE", "MAYA", "ÉGYPTIENNE"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Bain et douche",
      words: ["SAVON", "GEL", "SERVIETTE", "EAU"],
      difficulty: 1,
    },
    {
      category: "Chien",
      words: ["LAISSE", "COLIER", "LABRADOR", "OS"],
      difficulty: 2,
    },
    {
      category: "Pièces de vélo",
      words: ["ROUE", "SELLE", "GUIDON", "CHAÎNE"],
      difficulty: 3,
    },
    {
      category: "Égypte ancienne",
      words: ["PHARAON", "SCARABÉE", "TOMBE", "MOMIE"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Alimenté par pile",
      words: ["RADIO", "MONTRE", "JOUET", "HORLOGE"],
      difficulty: 1,
    },
    {
      category: "Boissons",
      words: ["THÉ", "JUS", "MOJITO", "BIÈRE"],
      difficulty: 2,
    },
    {
      category: "Vélos et cyclisme",
      words: ["VTT", "PISTE", "TRIATHLON", "MONTAGNE"],
      difficulty: 3,
    },
    {
      category: "Grèce ancienne",
      words: ["TROIE", "STATUE", "J.O", "PLATON"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "A la Compagne",
      words: ["ÂNE", "CHEVAL", "BOEUF", "POULE"],
      difficulty: 1,
    },
    {
      category: "Au Cinéma",
      words: ["LOGE", "ÉCRAN", "PLACEUR", "TORCHE"],
      difficulty: 2,
    },
    {
      category: "Fromage",
      words: ["MAROILLES", "CAMEMBERT", "GRUYÈRE", "ROQUEFORT"],
      difficulty: 3,
    },
    {
      category: "Armes anciennes",
      words: ["ÉPÉE", "AXE", "LANCE", "DAGUE"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Avant d'aller au lit",
      words: ["DOUCHE", "LIVRE", "PYJAMA", "COUVERTURE"],
      difficulty: 1,
    },
    {
      category: "Fête d'anniversaire",
      words: ["BALLONS", "MUSIQUE", "DANSE", "INVITÉS"],
      difficulty: 2,
    },
    {
      category: "Bébés",
      words: ["COUCHE", "BIBERON", "BERCEAU", "TÉTINE"],
      difficulty: 3,
    },
    {
      category: "Animaux Lourds",
      words: ["RHINOCÉROS", "CROCODILE", "ALLIGATOR", "ÉLÉPHANT"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Noir",
      words: ["GOUDRON", "CHARBON", "ENCRE", "BASALTE"],
      difficulty: 1,
    },
    {
      category: "Bibliothèque",
      words: ["LIVRES", "SILENCE", "PRÊTER", "RANGER"],
      difficulty: 2,
    },
    {
      category: "Anniversaire",
      words: ["GÂTEAU", "BOUGIE", "CADEAU", "FÊTE"],
      difficulty: 3,
    },
    {
      category: "À l'université",
      words: ["SPÉCIALITÉ", "LICENCE", "DIPLÔME", "COURS"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Noir et blanc",
      words: ["ZÈBRE", "PANDA", "ÉCHEC", "PIANO"],
      difficulty: 1,
    },
    {
      category: "Petit déjeuner",
      words: ["CÉRÉALE", "OEUF", "BEURRE", "JUS"],
      difficulty: 2,
    },
    {
      category: "Livres et édition",
      words: ["AUTEUR", "ÉDITEUR", "IMPRESSION", "PUBLICATION"],
      difficulty: 3,
    },
    {
      category: "Légumineuse",
      words: ["HARICOT", "FÈVE", "LENTILLE", "POIS"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Corps d'eau",
      words: ["LAC", "RIVIÈRE", "OCÉAN", "MER"],
      difficulty: 1,
    },
    {
      category: "Petit déjeuner",
      words: ["BRUNCH", "TOAST", "FRUIT", "BEURRE"],
      difficulty: 2,
    },
    {
      category: "Au bowling",
      words: ["QUILLE", "BOULE", "PISTE", "SCORE"],
      difficulty: 3,
    },
    {
      category: "Ours",
      words: ["BRUN", "POLAIRE", "NOIR", "LIPPU"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Corps en mouvement",
      words: ["COURIR", "SAUTER", "DANSER", "NAGER"],
      difficulty: 1,
    },
    {
      category: "Blanc éclatant",
      words: ["NEIGE", "LAIT", "PERLE", "COTON"],
      difficulty: 2,
    },
    {
      category: "Bulles",
      words: ["SAVON", "CHAMPAGNE", "AQUARIUM", "EAU"],
      difficulty: 3,
    },
    {
      category: "Couleurs magnifiques",
      words: ["TURQUOISE", "ÉCARLATE", "AMÉTHYSTE", "SAFRAN"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Parties du corps",
      words: ["TÊTE", "BRAS", "JAMBE", "MAIN"],
      difficulty: 1,
    },
    {
      category: "Matériaux de construction",
      words: ["BÉTON", "ACIER", "BRIQUE", "PLATRE"],
      difficulty: 2,
    },
    {
      category: "Affaires & Économie",
      words: ["MARCHÉ", "GAIN", "PROFIT", "BOURSE"],
      difficulty: 3,
    },
    {
      category: "Choses magnifiques",
      words: ["AUBE", "GALAXIE", "AURORE", "OCEAN"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Insectes",
      words: ["FOURMI", "ABEILLE", "MOUSTIQUE", "PAPILLON"],
      difficulty: 1,
    },
    {
      category: "Bâtiments",
      words: ["MAISON", "IMMEUBLE", "ÉGLISE", "MUSÉE"],
      difficulty: 2,
    },
    {
      category: "Cuisiner",
      words: ["MIJOTER", "RÔTIR", "PÉTRIR", "GRILLER"],
      difficulty: 3,
    },
    {
      category: "Biologie",
      words: ["CELLULE", "GÉNÉTIQUE", "ÉCOSYSTÈME", "ADN"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Près du lit",
      words: ["TABLE", "LAMPE", "RÉVEIL", "LIVRE"],
      difficulty: 1,
    },
    {
        category: "Grands félins",
        words: ["LION", "TIGRE", "LÉOPARD", "JAGUAR"],
        difficulty: 2,
    },
    {
      category: "Fournitures de camping",
      words: ["RÉCHAUD", "DUVET", "BÂCHE", "GOURDE"],
      difficulty: 3,
    },
    {
      category: "Vie des oiseaux",
      words: ["NID", "MIGRATION", "CHANT", "PLUMAGE"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "A la cafétéria",
      words: ["SANDWICH", "SALADE", "CROISSANT", "SOUFFLET"],
      difficulty: 1,
    },
    {
      category: "Peut être ennuyeux",
      words: ["FILM", "RÉUNION", "TRAJET", "ATTENTE"],
      difficulty: 2,
    },
    {
        category: "Camping",
        words: ["TENTE", "FEU", "LAMPE", "RANDONNÉE"],
        difficulty: 3,
    },
    {
        category: "Oiseaux",
        words: ["AIGLE", "FALCON", "MOUETTE", "COLIBRI"],
        difficulty: 3,
    },
  ],
  [
    {
      category: "Peut être tricoté",
      words: ["PULL", "ÉCHARPE", "BONNET", "CHAUSSETTE"],
      difficulty: 1,
    },
    {
      category: "Peut être brisé",
      words: ["VERRE", "PROMESSE", "OS", "COEUR"],
      difficulty: 2,
    },
    {
      category: "Peut être tenu",
      words: ["MAIN", "SAC", "STYLO", "CANNE"],
      difficulty: 3,
    },
    {
      category: "Pierres précieuse",
      words: ["RUBIS", "SAPHIR", "ÉMERAUDE", "DIAMANT"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Peut être tranché",
      words: ["PAIN", "FRUIT", "FROMAGE", "GÂTEAU"],
      difficulty: 1,
    },
    {
      category: "Peut être noué",
      words: ["CORDE", "CRAVATE", "LACET", "FOULARD"],
      difficulty: 2,
    },
    {
      category: "Peut être verrouillé",
      words: ["PORTE", "COFFRE", "FENÊTRE", "SERRURE"],
      difficulty: 3,
    },
    {
      category: "Bleu",
      words: ["MÉTHYLÈNE", "MER", "CIEL", "SAPHIR"],
      difficulty: 4,
    },
  ],
  [
    {
      category: "Peut être collant",
      words: ["MIEL", "GOMME", "PÂTE", "CARAMEL"],
      difficulty: 1,
    },
    {
      category: "Portège du soleil",
      words: ["LUNETTES", "CHAPEAU", "PARASOL", "CRÈME"],
      difficulty: 2,
    },
    {
      category: "Peut être gaspillé",
      words: ["TEMPS", "ARGENT", "ÉNERGIE", "TALENT"],
      difficulty: 3,
    },
    {
      category: "Marche sur 2 pieds",
      words: ["PINGOUIN", "OISEAU", "DINOSAURE", "ROBOT"],
      difficulty: 4,
    },
  ],
];
