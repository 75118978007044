import React from "react";
import logo from "./logo.png";


function Header() {
  return (
     <header className="flex justify-center bg-[#000742]">
      <a href="/">
         <img className="text-center" src={logo} alt="Logo" />
         </a>
    </header>
  );
}

export default Header;
