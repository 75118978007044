import React from "react";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function Footer() {
  return (
    <div className="flex flex-col justify-center items-center bg-slate-800 text-white p-4">
      <div className="items-center mx-auto gap-8 mb-8">
        <div>
          <h3 className="text-cyan-200 font-bold mt-4 mb-4">Nous Suivre</h3>
          <a
            href="https://twitter.com/giprossolution"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon
              fontSize="large"
              className="white hover:text-blue-300 mr-4"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/gipros/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon
              fontSize="large"
              className="white space-x-2 hover:text-blue-300"
            />
          </a>
        </div>
        <div>
          <h3 className="text-cyan-200 font-bold mt-4">Contact</h3>
          <div className="px2">
            <a
              href="mailto:contact@connexions.fun"
              className="white hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact@connexions.fun
            </a>
          </div>
          <div className="px-2">
            <a
              href="https://www.connexions.fun/"
              className="white hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              © https://www.connexions.fun
            </a>
          </div>
        </div>
        <div>
          <div>
            <h3 className="text-cyan-200 font-bold mb-4 mt-4"> Terms</h3>
            <div className="text-white text-sm">
              <a
                href="/politique.html"
                className="white hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Politique de confidentialité
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
